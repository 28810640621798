<template>
  <div>
    <div class="card-toolbar mb-5">
<!--      v-if="$can('commission.create')"-->
      <router-link  to="/agents/commission/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('commission.add_commission') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-6">
              <label for="name">{{ $t('commission.agent_name') }}</label>
              <multiselect
                  v-model="agent"
                  :placeholder="$t('commission.agent_name')"
                  label="name"
                  track-by="id"
                  :options="agents"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getAgents($event)"
                  :internal-search="false"
              >
              </multiselect>
            </div>
            <div class="form-group col-md-6">
              <label for="from_date">{{ $t('commission.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_date">{{ $t('commission.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="from_commission">{{ $t('commission.from_commission') }}</label>
              <input v-model="filters.from_commission" type="number" id="from_commission" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_commission">{{ $t('commission.to_commission') }}</label>
              <input v-model="filters.to_commission" type="number" id="to_commission" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="from_discount">{{ $t('commission.from_discount') }}</label>
              <input v-model="filters.from_discount" type="number" id="from_discount" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_discount">{{ $t('commission.to_discount') }}</label>
              <input v-model="filters.to_discount" type="number" id="to_discount" class="form-control">
            </div>


            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
<!--        <div class="row justify-content-end p-4">-->
<!--          <export-data :data-list="dataList" :fields="json_fields"-->
<!--                       :file-name="$t('agents.agents_management')"></export-data>-->
<!--        </div>-->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="interval_no" slot-scope="props">
            <span>{{props.row.interval_no}} {{ props.row.interval_type == 1 ? $t('commission.year') : $t('commission.month')}}</span>
          </template>
          <template slot="actions" slot-scope="props">
<!--            v-if="$can('agents.update')"-->
            <v-btn v-b-tooltip.hover :title="$t('edit')"  icon color="pink" :to="`/agents/commission/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-agents",
  components: {},
  data() {
    return {
      mainRoute: 'agents/commissions',
      subMainRoute: 'agents/commission',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],

      filter: {
        sortBy: 'id',
      },
      filters: {
        agent_id: null,
        from_date: null,
        to_date: null,
        from_commission: null,
        to_commission: null,
        from_discount: null,
        to_discount: null,
      },
      columns: ['agent_name', 'applied_date', 'commission_rate', 'interval_no', 'actions'],
      data: [],

      agent: null,
      agents: [],
    }
  },
  computed: {
    // json_fields: function () {
    //   let fields = {};
    //   fields[this.$t('users.name')] = 'name';
    //   fields[this.$t('users.email')] = 'email';
    //   fields[this.$t('users.created_at')] = 'created_at';
    //
    //   return fields;
    // },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          agent_name: that.$t('commission.agent_name'),
          applied_date: that.$t('commission.applied_date'),
          // discount_rate: that.$t('commission.discount_rate'),
          commission_rate: that.$t('commission.commission_rate'),
          interval_no: that.$t('commission.general_interval'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    "agent": function (val) {
      if (val && val.id) {
        this.filters.agent_id = val.id;
      }else {
        this.filters.agent_id = null;
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.commission_management")}]);
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.agent_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.from_commission = null;
      this.filters.to_commission = null;
      this.filters.from_discount = null;
      this.filters.to_discount = null;
      this.agent = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },

    getAgents(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(this.mainRouteDependency+"/agents", {params: {filter: filter}}).then((response) => {
          this.agents = response.data.data;
        });
      }
    },

  },
};
</script>
